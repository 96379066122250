







































.bubs-web-layout {
  display: grid;
  grid-template-areas: 'left right';
}
